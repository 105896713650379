<template>
  <SubPageWrapper empty-cols>
    <template #loader>
      <SlOverlay :show="isLoading && !hasData" />
    </template>

    <UnsavedConfirmModal :id="modalIds.UNSAVED_CONFIRM" />

    <template #left-col>
      <SlTabList
        :value="$sl_routeMeta.tab"
        :tabs="usersTabs"
      />
    </template>
    <router-view />
  </SubPageWrapper>
</template>

<script>
import { mapState } from 'vuex';
import UnsavedConfirmModal from '@/components/Modals/Shared/UnsavedConfirmModal';
import { usersTabsConfig } from '@/config/users/users.config';
import modalIds from '@/config/shared/modalsId.config';
import { sendRequests } from '@/helpers/lastChange/sendRequests.js';
import { componentNames } from '@/config/app.config.js';
import { routeNames } from '@/config/router/router.config';

export default {
  name: 'Users',
  components: {
    UnsavedConfirmModal
  },
  data() {
    return {
      modalIds,
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      users: (state) => state.userAccess.users,
      roles: (state) => state.userAccess.roles,
      catalogs: (state) => state.userAccess.permissions
    }),
    usersTabs() {
      return usersTabsConfig(this);
    },
    hasDataByRouteName() {
      return {
        [routeNames.USERS]: this.users,
        [routeNames.ROLES]: this.roles,
        [routeNames.CATALOGS]: this.catalogs,
        [routeNames.CREATE_ROLE]: true,
        [routeNames.EDIT_ROLE]: true,
        [routeNames.CREATE_CATALOG]: true,
        [routeNames.EDIT_CATALOG]: true
      };
    },
    hasData() {
      return this.hasDataByRouteName[this.$sl_routeName];
    }
  },
  async beforeMount() {
    try {
      this.isLoading = true;

      await sendRequests(componentNames.USERS);
    } finally {
      this.isLoading = false;
    }
  }
};
</script>
