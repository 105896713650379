<template>
  <SlModal
    :id="id"
    :width="420"
    no-header
    @created="onCreated"
  >
    <div class="modal-content unsaved-data">
      <icon
        data="@icons/style_save.svg"
        class="fill-off size-48"
      />
      <div class="unsaved-data__title heading-4-sb grey-90">
        {{ $t('Web.Modals.TitleUnsavedData') }}
      </div>
      <div class="unsaved-data__text body-1 grey-70">
        <div v-if="warningText">
          {{ warningText }}
        </div>
        {{ $t('Web.Modals.TextDiscardChangesAndExit') }}
      </div>
    </div>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          full-width
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton
          full-width
          @click="handleExit"
        >
          {{ $t('Web.Modals.BtnExit') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';

export default {
  name: 'UnsavedConfirmModal',
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      warningText: '',
      exitCallback: null
    };
  },
  methods: {
    onHide() {
      this.warningText = '';
      this.exitCallback = null;
    },
    handleCancel() {
      this.hideModal(this.id);
    },
    handleExit() {
      this.exitCallback && this.exitCallback();
      this.hideModal(this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/modals/unsaved-data-modal.scss";
</style>